import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Link from 'next/link';
import Text from '../../app/Text';
import Channel from '../../card/channel/Channel';
import CustomHorizontalScrollView from '../../app/CustomHorizontalScroll';
import { setViewAllData } from '../../../store/slices/viewAll';
import contentConstants from '../../../utils/constants/content';
import routeConstants from '../../../utils/constants/routes';
import styles from './styles';

function CustomChannels({ data, label, viewAllLink, showViewAllLink = true }) {
  const [showViewAll, setShowViewAll] = useState(true);
  const dispatch = useDispatch();

  function handleClick() {
    if (!viewAllLink) {
      dispatch(
        setViewAllData({
          objectArray: data,
          selectedRow: label,
          rowLabel: contentConstants.CONTENT_UI_TYPES.CHANNELS,
        })
      );
    }
  }

  if (!data || !data.length) {
    return null;
  }
  return (
    <div className="channel-container">
      <div className="channel-list-header">
        <Text
          type="h4"
          color="b100"
          weight="regular"
          style={{ marginBottom: 12 }}>
          {label}
        </Text>
        {showViewAll && showViewAllLink && (
          <Link
            href={viewAllLink || routeConstants.PAGE_VIEW_ALL}
            prefetch={false}
            legacyBehavior>
            <a className="view-all clickable" onClick={handleClick}>
              <Text
                type="body"
                color="b64"
                weight="regular"
                style={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  textDecoration: 'none',
                  marginBottom: 8,
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: 'vertical',
                }}>
                View All
              </Text>
            </a>
          </Link>
        )}
      </div>
      <CustomHorizontalScrollView
        data={data}
        setShowViewAll={setShowViewAll}
        renderItem={(channel) => (
          <Channel key={channel.id} channel={channel} id={channel.id} />
        )}
      />
      <style jsx>{styles}</style>
    </div>
  );
}

export default CustomChannels;
